export const ROUTES = {
    // Some routes cannot use '-' because CIS does not support it
    HOME: '/',
    LOGIN: '/login',
    CAR: '/cars',
    MEMBER: '/members',
    FORGET_PASSWORD: '/forget_password',
    RESET_PASSWORD: '/reset-password',
    CHANGE_PASSWORD: '/change_password',
    SET_PASSWORD: '/set_password',
    PASSWORD_SUCCESS: '/password-success',
    PROFILE: '/profile',
    TODAY_PRICE: '/today-price',
    FORGET_PASSWORD_SENT: '/forget-password/sent',
    CHARGING_BILL: '/charging-bill',

    CONTRACT: {
        BASE: '/contracts',
        DETAIL: '/contracts/:id',
    },

    // Grouping electric-related routes
    ELECTRIC: {
        BASE: '/electric',
        BILLING: '/electric/billing'
    }
};

export const paths = [
    ROUTES.LOGIN,
    ROUTES.CONTRACT.BASE,
    ROUTES.CAR,
    ROUTES.MEMBER,
    ROUTES.FORGET_PASSWORD,
    ROUTES.RESET_PASSWORD,
    ROUTES.CHANGE_PASSWORD,
    ROUTES.SET_PASSWORD,
    ROUTES.PASSWORD_SUCCESS,
    ROUTES.PROFILE,
    ROUTES.CONTRACT.DETAIL,
    ROUTES.TODAY_PRICE,
    ROUTES.FORGET_PASSWORD_SENT,
    ROUTES.ELECTRIC.BASE,
    ROUTES.ELECTRIC.BILLING,
    ROUTES.CHARGING_BILL
];

export const publicRoutes = [
    ROUTES.LOGIN,
    ROUTES.FORGET_PASSWORD,
    ROUTES.RESET_PASSWORD,
    ROUTES.CHANGE_PASSWORD,
    ROUTES.SET_PASSWORD,
    ROUTES.PASSWORD_SUCCESS,
    ROUTES.TODAY_PRICE,
    ROUTES.FORGET_PASSWORD_SENT,
    ROUTES.ELECTRIC.BASE,
    ROUTES.ELECTRIC.BILLING,
];
