import { Route, Switch, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// project imports
import { useQuery } from 'react-query';
import React, { lazy } from 'react';
import { useFromQueryParam } from '../helpers/getFromQueryParam';
import NotAccessPage from '../components/common/NotAccessPage';
import { ROUTES, paths, publicRoutes } from '../constants';
import ErrorPage from '../components/common/ErrorPage';
import { fetchCustomerInfo } from '../api/CustomerApi';
import NotFound from '../components/common/NotFound';
import Loadable from '../components/common/Loadable';
import { SET_PROFILE } from '../redux/_user.redux';
import { checkContract } from '../api/ContractApi';
import { useToast } from '../hooks/ToastProvider';
import { checkResetToken } from '../api/AuthApi';
import MainLayout from './../layout/MainLayout';

// dashboard routing
const LoginDefault = Loadable(lazy(() => import('../views/login')));
const ContractDefault = Loadable(lazy(() => import('../views/contract')));
const CarDefault = Loadable(lazy(() => import('../views/car')));
const MemberDefault = Loadable(lazy(() => import('../views/member')));
const ForgetPasswordDefault = Loadable(lazy(() => import('../views/forgetPassword')));
const ResetPasswordDefault = Loadable(lazy(() => import('../views/resetPassword')));
const ChangePasswordDefault = Loadable(lazy(() => import('../views/changePassword')));
const SetPasswordDefault = Loadable(lazy(() => import('../views/setPassword')));
const PasswordSuccessDefault = Loadable(lazy(() => import('../views/passwordSuccess')));
const ProfileDefault = Loadable(lazy(() => import('../views/profile')));
const ContractDetailDefault = Loadable(lazy(() => import('../views/contract/[id]')));
const TodayPriceDefault = Loadable(lazy(() => import('../views/todayPrice')));
const ForgetPasswordSentDefault = Loadable(lazy(() => import('../views/forgetPassword/forgetPasswordSent')));
const ElectricDefault = Loadable(lazy(() => import('../views/electric')));
const ElectricBillingDefault = Loadable(lazy(() => import('../views/electric/billing')));
const ChargingBillDefault = Loadable(lazy(() => import('../views/chargingBill')));

//-----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const profile = useSelector((state) => state.user.profile);
    const currentPath = location.pathname;
    const searchParams = new URLSearchParams(location.search);
    const contractId = searchParams.get('contractId');
    const token = searchParams.get('token');
    const resetToken = searchParams.get('reset_token');
    const accountId = searchParams.get('sfid');
    const hasViewAccess = currentPath === ROUTES.CAR;
    const from = useFromQueryParam();
    const { showToast } = useToast();

    const {
        data: userContractRole,
        isLoading: isLoadingCheckContract,
        error: checkContractError
    } = useQuery(['checkContract', contractId], () => checkContract(contractId, hasViewAccess), {
        enabled: (!!contractId && paths.includes(currentPath) && currentPath === ROUTES.CAR) || currentPath === ROUTES.MEMBER
    });
    const { isLoading: isLoadingCheckResetToken, error: checkResetTokenError } = useQuery(
        ['checkResetToken', resetToken],
        () => checkResetToken(resetToken),
        {
            enabled: !!resetToken && currentPath === ROUTES.RESET_PASSWORD
        }
    );

    if (token) {
        localStorage.setItem('token', token);
    }

    const isUserLoggedIn = Boolean(localStorage.getItem('token'));
    const isChangePasswordNotLoggedIn = currentPath === ROUTES.CHANGE_PASSWORD && !!accountId;
    const isNotPublicPage =
        (!publicRoutes.includes(currentPath) && isUserLoggedIn && !checkContractError && !isChangePasswordNotLoggedIn) ||
        (currentPath === ROUTES.CHANGE_PASSWORD && !currentPath.includes('?'));
    const isPageNotAccess = !publicRoutes.includes(currentPath) && !isUserLoggedIn;
    const isLoggedOut = !localStorage.getItem('token');
    const shouldFetchCustomer = !isLoggedOut && !profile && !publicRoutes.includes(currentPath) && from !== 'cis' && !resetToken;

    const { error: fetchCustomerError, isLoading: isLoadingFetchCustomer } = useQuery(['customers'], () => fetchCustomerInfo(), {
        enabled: shouldFetchCustomer,
        onSuccess: (data) => {
            dispatch({ type: SET_PROFILE, profile: data || null });
        },
        onError: () => {
            showToast();
        }
    });
    const renderMainLayout = (component) => <MainLayout isNotPublicPage={isNotPublicPage}>{component}</MainLayout>;

    const renderRoutes = () => (
        <Switch location={location} key={currentPath}>
            <Route path={ROUTES.LOGIN} component={LoginDefault} />
            <Route path={ROUTES.CONTRACT.DETAIL} component={ContractDetailDefault} />
            <Route path={ROUTES.CONTRACT.BASE} component={ContractDefault} />
            <Route path={ROUTES.CAR} component={() => <CarDefault userContractRole={userContractRole} />} />
            <Route path={ROUTES.MEMBER} component={MemberDefault} />
            <Route path={ROUTES.FORGET_PASSWORD_SENT} component={ForgetPasswordSentDefault} />
            <Route path={ROUTES.FORGET_PASSWORD} component={ForgetPasswordDefault} />
            <Route path={ROUTES.RESET_PASSWORD} component={ResetPasswordDefault} />
            <Route path={ROUTES.CHANGE_PASSWORD} component={ChangePasswordDefault} />
            <Route path={ROUTES.SET_PASSWORD} component={SetPasswordDefault} />
            <Route path={ROUTES.PROFILE} component={ProfileDefault} />
            <Route path={ROUTES.TODAY_PRICE} component={TodayPriceDefault} />
            <Route path={ROUTES.PASSWORD_SUCCESS} component={PasswordSuccessDefault} />
            <Route path={ROUTES.ELECTRIC.BILLING} component={ElectricBillingDefault} />
            <Route path={ROUTES.ELECTRIC.BASE} component={ElectricDefault} />
            <Route path={ROUTES.CHARGING_BILL} component={ChargingBillDefault} />;
        </Switch>
    );

    if (!paths.includes(currentPath)) {
        return renderMainLayout(<Route path={currentPath} component={NotFound} />);
    }

    if (checkContractError) {
        return renderMainLayout(<Route path={currentPath} component={ErrorPage} />);
    }

    if (isPageNotAccess || checkResetTokenError || (!resetToken && currentPath === ROUTES.RESET_PASSWORD) || fetchCustomerError) {
        return renderMainLayout(<Route path={currentPath} component={NotAccessPage} />);
    }

    return (
        <>
            {!isLoadingCheckContract && !isLoadingCheckResetToken && !isLoadingFetchCustomer ? (
                <Route path={paths}>{renderMainLayout(renderRoutes())}</Route>
            ) : (
                <MainLayout />
            )}
        </>
    );
};

export default MainRoutes;
