import PropTypes from 'prop-types';
import React from 'react';
// material-ui
import { Container, Box } from '@mui/material';
import Header from './Header';
import Footer from './Footer';
import { useElectricPage } from '../../helpers/isElectricPage';

//-----------------------|| MAIN LAYOUT ||-----------------------//

const MainLayout = ({ children, isNotPublicPage }) => {
    const isElectricPage = useElectricPage();
    return (
        <Box style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Header isNotPublicPage={isNotPublicPage} />
            <Box
                style={{
                    backgroundColor: '#c8e9fa',
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '64px 16px'
                }}
            >
                <Container maxWidth={isElectricPage ? 'lg' : 'sm'}>{children}</Container>
            </Box>
            <Footer />
        </Box>
    );
};

MainLayout.propTypes = {
    children: PropTypes.node
};

export default MainLayout;
