import jwtDecode from 'jwt-decode';
import { ApiFactory } from './Api';

const api = new ApiFactory();

// Helper function to get customer ID from token
function getCustomerId() {
    const token = localStorage.getItem('token');
    if (!token) throw new Error('No token found');
    const customer = jwtDecode(token);
    return customer.id;
}

// Fetch customer info
async function fetchCustomerInfo() {
    try {
        const customerId = getCustomerId();
        const response = await api.get(`/customers/${customerId}`);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch customer info:', error);
        throw error;
    }
}

// Edit customer profile
async function editProfile(request) {
    try {
        const customerId = getCustomerId();
        const response = await api.put(`/customers/${customerId}/edit_profile`, request);
        return response.data;
    } catch (error) {
        console.error('Failed to edit profile:', error);
        throw error;
    }
}

// Send OTP to change email
async function sendOtpToChangeEmail(request) {
    try {
        const customerId = getCustomerId();
        const response = await api.post(`/customers/${customerId}/send_otp_to_change_email`, request);
        return response.data;
    } catch (error) {
        console.error('Failed to send OTP to change email:', error);
        throw error;
    }
}

// Verify OTP to change email
async function verifyOtpChangeEmail(request) {
    try {
        const customerId = getCustomerId();
        const response = await api.post(`/customers/${customerId}/verify_otp_change_email`, request);
        return response.data;
    } catch (error) {
        console.error('Failed to verify OTP for email change:', error);
        throw error;
    }
}

// Send OTP to change phone
async function sendOtpToChangePhone(request) {
    try {
        const customerId = getCustomerId();
        const response = await api.post(`/customers/${customerId}/send_otp_to_change_phone`, request);
        return response.data;
    } catch (error) {
        console.error('Failed to send OTP to change phone:', error);
        throw error;
    }
}

// Verify OTP to change phone
async function verifyOtpChangePhone(request) {
    try {
        const customerId = getCustomerId();
        const response = await api.post(`/customers/${customerId}/verify_otp_change_phone`, request);
        return response.data;
    } catch (error) {
        console.error('Failed to verify OTP for phone change:', error);
        throw error;
    }
}

export { fetchCustomerInfo, editProfile, sendOtpToChangeEmail, verifyOtpChangeEmail, sendOtpToChangePhone, verifyOtpChangePhone };
