import React from 'react';
import { useLocation } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@mui/material';

// project imports
import config from './../../../config';
import Logo from './../../../components/common/Logo';
import { useFromQueryParam } from '../../../helpers/getFromQueryParam';
import { ROUTES } from '../../../constants';

//-----------------------|| MAIN LOGO ||-----------------------//

const LogoSection = ({ disabled }) => {
    const from = useFromQueryParam();
    const isUserLoggedIn = Boolean(localStorage.getItem('token'));

    const portalUrl = process.env.REACT_APP_PORTAL_URL;
    const location = useLocation();
    const currentPath = location.pathname
    const isOnContractBase = currentPath === ROUTES.CONTRACT.BASE; 

    const linkPath = from === 'cis'
        ? portalUrl
        : isUserLoggedIn
        ? ROUTES.CONTRACT.BASE
        : config.defaultPath;

    const isDisabled = disabled || (isOnContractBase && isUserLoggedIn);

    return (
        <ButtonBase disableRipple component="a" href={linkPath} disabled={isDisabled}>
            <Logo />
        </ButtonBase>
    );
};

export default LogoSection;
