import { Box, AppBar, Container, Toolbar, IconButton, Menu, MenuItem, Avatar, Tooltip, ListItemIcon } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import PersonIcon from '@mui/icons-material/Person';
import KeyIcon from '@mui/icons-material/Key';
import { useHistory } from 'react-router-dom';
import { Logout } from '@mui/icons-material';
import { useState } from 'react';
import { useFromQueryParam } from '../../../helpers/getFromQueryParam';
import { useElectricPage } from '../../../helpers/isElectricPage';
import useGlobalMutation from '../../../hooks/useGlobalMutation';
import { DELETE_PROFILE } from '../../../redux/_user.redux';
import { useToast } from '../../../hooks/ToastProvider';
import { logout } from '../../../api/AuthApi';
import { ROUTES } from '../../../constants';
import LogoSection from '../LogoSection';

//-----------------------|| MAIN NAVBAR / HEADER ||-----------------------//

const Header = ({ isNotPublicPage }) => {
    const isElectricPage = useElectricPage();
    const { showToast } = useToast();
    const dispatch = useDispatch();
    const from = useFromQueryParam();
    const profile = useSelector((state) => state.user.profile);
    const history = useHistory();
    const logoutMutation = useGlobalMutation(logout, {
        onSuccess: async () => {
            localStorage.removeItem('token');
            dispatch({ type: DELETE_PROFILE });
            history.push(ROUTES.LOGIN);
        },
        onError: () => {
            showToast();
        }
    });
    const handleProfile = () => {
        history.push(ROUTES.PROFILE);
    };
    const handleChangePassword = () => {
        history.push(ROUTES.CHANGE_PASSWORD);
    };
    const handleLogout = () => {
        logoutMutation.mutate();
    };
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const getIconCharacter = (name) => {
        return name.charAt(0).toUpperCase();
    };
    return (
        <AppBar position="static" color="">
            <Container maxWidth={isElectricPage ? 'lg' : 'sm'}>
                <Toolbar disableGutters>
                    <Box component="span" sx={{ flexGrow: 1 }}>
                        <LogoSection disabled={from === 'app'} />
                    </Box>
                    {isNotPublicPage && from !== 'app' && profile && (
                        <>
                            <Tooltip title="アカウント設定">
                                <IconButton onClick={handleClick} size="small" sx={{ mx: 2 }}>
                                    <Avatar sx={{ width: 32, height: 32 }}>{getIconCharacter(profile.name)}</Avatar>
                                </IconButton>
                            </Tooltip>
                            <Menu
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                onClick={handleClose}
                                slotProps={{
                                    paper: {
                                        elevation: 0,
                                        sx: {
                                            overflow: 'visible',
                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                            mt: 1.5,
                                            '& .MuiAvatar-root': {
                                                width: 32,
                                                height: 32,
                                                ml: -0.5,
                                                mr: 1
                                            },
                                            '&::before': {
                                                content: '""',
                                                display: 'block',
                                                position: 'absolute',
                                                top: 0,
                                                right: 14,
                                                width: 10,
                                                height: 10,
                                                bgcolor: 'background.paper',
                                                transform: 'translateY(-50%) rotate(45deg)',
                                                zIndex: 0
                                            }
                                        }
                                    }
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                <MenuItem onClick={handleProfile}>
                                    <ListItemIcon>
                                        <PersonIcon color="primary" />
                                    </ListItemIcon>
                                    プロフィール
                                </MenuItem>
                                <MenuItem onClick={handleChangePassword}>
                                    <ListItemIcon>
                                        <KeyIcon color="primary" />
                                    </ListItemIcon>
                                    パスワード変更
                                </MenuItem>
                                <MenuItem onClick={handleLogout}>
                                    <ListItemIcon>
                                        <Logout color="primary" />
                                    </ListItemIcon>
                                    ログアウト
                                </MenuItem>
                            </Menu>
                        </>
                    )}
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default Header;
