import { Box, Card, Link, Typography } from '@mui/material';
import { useFromQueryParam } from '../../helpers/getFromQueryParam';
import NotFoundImage from '../../assets/images/not_found.png';

function NotFound() {
    const from = useFromQueryParam();
    return (
        <Card style={{ textAlign: 'center', padding: '30px', borderRadius: '25px' }}>
            <img src={NotFoundImage} style={{ maxWidth: 150 }} alt="logo" />
            <Typography variant="h2" mt={1}>
                ページが見つかりません
            </Typography>
            {from !== 'app' && (
                <Box mt={3}>
                    <Link
                        fontSize={22}
                        underline="hover"
                        href={`${window.location.origin}/login`}
                        onClick={() => {
                            localStorage.removeItem('token');
                        }}
                        style={{
                            fontWeight: 700
                        }}
                    >
                        TOPページへ
                    </Link>
                </Box>
            )}
        </Card>
    );
}

export default NotFound;
