import { ApiConst } from "../constants";
import { JwtHelper } from "../helpers/jwt";
import { LOGOUT, UPDATE_TOKEN } from "./_auth.redux";
import dayjs from 'dayjs';

const apiRequest = (store, next, action, api) => {

  const {types, promise, ...rest} = action;
  const [REQUEST, SUCCESS, FAIL] = types;
  const actionPromise = promise(api, store);

  if(!SUCCESS || !FAIL) {
    return actionPromise;
  }

  return new Promise((resolve, reject) => {

    next({type: REQUEST, ...rest});
    
    actionPromise.then(
      (data) => {
        store.dispatch({type: SUCCESS, data, ...rest})
        resolve(data)
      },
      (error) => {
        store.dispatch({type: FAIL, error, ...rest})
        reject(error)
      }
    ).catch((error)=> {
      console.error('MIDDLEWARE ERROR:', error);
      reject(error)
    });
  })
}

const forceLogout = (api, next, error = null) => {
  api.token = null;
  next({
    type: LOGOUT, error
  })
}

export default function apiMiddleware(api) {
  return store => next => action => {

    if (typeof action.promise === 'function') {


      // store.dispatch({ type: ASYNC_START, subtype: action.type });

      // if action doesnt require secure, next
      // ex: login request
      if(!action.secure) {
        return apiRequest(store, next, action, api);
      }

      const token = store.getState().auth.token;
      const refreshToken = store.getState().auth.refreshToken;

      // if refresh token is invalid, force login to get new refresh token
      if(!token || !refreshToken || !JwtHelper.isValid(refreshToken)) {
        return forceLogout(api, next);
      }
      
      const refreshThreshold = (new Date().getTime() + 300000); // 5 minutes from now

      // if token still valid and expires enough long, next
      // else, request grant new token before send real request
      if(!JwtHelper.isValid(token) ||  dayjs(refreshThreshold).isAfter(dayjs(JwtHelper.expiresAt(token)))) {
        return api.post(ApiConst.REFRESH_TOKEN, {refreshToken})
          .then(
            (result) => {
              api.token = result.token;
              next({
                type: UPDATE_TOKEN, data: result
              });
            },
            error => {
              forceLogout(api, next, error)
            }
          )
          .then(_ => {
            return apiRequest(store, next, action, api)
          })
      }

      // if refresh token is valid, try request new token,
      // then next
      api.token = token;
      return apiRequest(store, next, action, api);
    }

    next(action)
  }
}
